@tailwind base;
@tailwind components;
@tailwind utilities;


/* Classes for gallery carousel */
@layer components{
  .block-1{
      @apply max-w-[40vw] max-h-full select-none left-5 z-0 scale-[0.5]
  }
  .block-2{
      @apply max-w-full max-h-full select-none z-10
  }
  .block-3{
      @apply max-w-[40vw] max-h-full select-none right-5 z-0 scale-[0.5]
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}