a:hover {
  cursor: pointer;
}

.bg-image-parallax {
  background-image: url("../../../public/bgmaybe.svg");
}

/* .herohero {
  background-image: url("../../../public/bgmaybe.svg");
} */
